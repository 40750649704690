.hovered {
    display:none;
}

.group:hover .default {
    display:none;
}

.group:hover .hovered {
    display:block;
}

.group:hover .instagram {
    background-size: 50%;
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
